import { getUnvalidQuery } from "@/editor_core/common";

/**
 * 生成图表配置
 * @param {*} proxy
 * @param {*} props
 * @param {*} chartOption
 * @param {*} chartData
 * @param {*} queryFields
 * @returns
 */
export const generateChartOption = (
  proxy,
  props,
  chartOption,
  chartData,
  queryFields,
) => {
  // 检测所有查询规则是否满足
  if (getUnvalidQuery(props.query.area)) {
    return;
  }

  // 生成维度、度量数据备用 - 装入queryFields
  generateQueryFields(props, queryFields);

  // 生成报表的Series参数
  // generateSeriesOption(props, chartOption)

  // 生成聚合数据备用
  const aggregationChartData = generateaAggregationChartData(
    chartData,
    queryFields,
  );

  // 生成options数据备用
  const { dimensionData, seriesData } = generateOptionData(
    props,
    chartData,
    aggregationChartData,
    queryFields,
  );

  // 将维度、度量等数据放入dimensionData、measureData中备用
  // generateOption(chartData, queryFields, queryDatas)

  // 获取图表option数据
  if (chartOption && dimensionData.length && seriesData.length) {
    proxy.genCustomOption(chartOption, dimensionData, seriesData);
  }
};

/**
 * 提取查询字段（维度、度量 对应的名称）
 * @param {*} props
 * @param {*} queryFields
 */
const generateQueryFields = (props, queryFields) => {
  // 初始化数据容器
  queryFields.dimension = [];
  queryFields.measure = [];

  // 遍历该配置
  props.query.area.forEach((item) => {
    /**
     * 
     * item格式示例（queryName为组件字段，其他为查询器合并入的字段）
     * {
          "label": "类别轴/维度",
          "value": [
              {
                  "name": "省份",
                  "value": "address"
              }
          ],
          "rule": {
              "type": "dimension",
              "required": true
          },
          "queryName": "area_category"
       }
      */
    // 当前领域是否配置type（对应：dimension、measure等）
    if (item.rule && item.rule.type) {
      // 类目轴 或 值轴
      if (["area_category", "area_value"].indexOf(item.queryName) > -1) {
        // 遍历用户选择的的字段
        item.value.forEach((valueItem) => {
          // 把 字段 放到对应领域的容器中去
          queryFields[item.rule.type].push(valueItem.name);
        });
      }
    }
  });
};

/**
 * 生成聚合数据备用
 * @param {*} chartData
 * @param {*} queryFields
 * @returns
 */
const generateaAggregationChartData = (chartData, queryFields) => {
  // 由于维度仅支持一个，故直接去下标为0的数据
  const _dimension = queryFields.dimension[0];
  // 再转为列表
  return Object.entries(
    // 先按维度聚合为键值对
    chartData.reduce((sum, current) => {
      const name = current[_dimension];
      if (!sum[name]) {
        sum[name] = [];
      }
      sum[name].push(current);
      return sum;
    }, {}),
  );
};

/**
 * 生成options数据备用
 * @param {*} props
 * @param {*} chartData
 * @param {*} aggregationChartData
 * @param {*} queryFields
 * @returns
 */
const generateOptionData = (
  props,
  chartData,
  aggregationChartData,
  queryFields,
) => {
  // 是否聚合
  const isAggregation = true;
  // 维度字段
  const dimensionField = queryFields.dimension[0];
  // 维度数据
  const dimensionData = isAggregation
    ? aggregationChartData.map((item) => item[0])
    : chartData.map((item) => item[dimensionField]);
  // 度量字段列表
  const measureFields = props.query.area.find(
    (item) => item.queryName == "area_value",
  );
  // 系列数据
  const seriesData = measureFields.value.map((item) => {
    // 外显名称，ID，聚合方式
    const { name, aggregationType = 1 } = item;
    // 序列
    const serie = { name };
    // 聚合
    if (isAggregation) {
      serie.data = aggregationChartData.map((item) => {
        let val = 0,
          arr = item[1].map((i) => Number(i[name]));
        // 求和
        if (aggregationType == 1) {
          val = arr.reduce((sum, curr) => {
            return (sum += curr);
          }, 0);
          // 求平均值
        } else if (aggregationType == 2) {
          val = arr.reduce((sum, curr) => {
            return (sum += curr);
          }, 0);
          val = val / arr.length;
          // 求最大值
        } else if (aggregationType == 3) {
          val = Math.max(...arr);
          // 求最小值
        } else if (aggregationType == 4) {
          val = Math.min(...arr);
        }
        return Number(val.toFixed(2));
      });
      // 不聚合
    } else {
      serie.data = chartData.map((item) => item[name]);
    }
    return serie;
  });
  return { dimensionData, seriesData };
};
