import { computed, defineComponent } from "vue";
import "./selectTheme.scss";
import { ElCarousel, ElCarouselItem, ElImage } from "element-plus";
import { themeList } from "@/config";

export default defineComponent({
  name: "select-theme",
  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  emits: ["update:value", "change"],
  setup(props, ctx) {
    const valueData = computed({
      get: () => {
        return props.value || "";
      },
      set: (val) => {
        ctx.emit("update:value", val);
      },
    });
    const initialIndex = computed(() => {
      let _index = 0;
      _index = themeList.findIndex((item) => item.theme === valueData.value);
      return _index > -1 ? _index : 0;
    });
    const handleValueChange = (index) => {
      const value = themeList[index].theme;
      valueData.value = value;
      ctx.emit("change", value);
    };

    const renderView = () => {
      return (
        <ElCarousel
          interval={5000}
          type="card"
          indicator-position={"none"}
          arrow={"always"}
          autoplay={false}
          initial-index={initialIndex.value}
          class="select-theme"
          onChange={handleValueChange}
          key={valueData.value}
        >
          {themeList.map((item) => {
            return (
              <ElCarouselItem key={item}>
                <ElImage src={item.url} />
                <div className="label">{item.label}</div>
              </ElCarouselItem>
            );
          })}
        </ElCarousel>
      );
    };
    return () => {
      return renderView();
    };
  },
});
