import EmptyImg from "@/assets/img/card/empty/pie.png";
import { baseConfig, seriesConfig } from "@/config/chartStyleConfig";
import _ from "lodash";

const centerConfig = [
  ["30%", "85%"],
  [
    ["60%", "85%"],
    ["25%", "50%"],
  ],
  [
    ["68%", "85%"],
    ["41.5%", "58.5%"],
    ["15%", "32%"],
  ],
  [
    ["75%", "85%"],
    ["55%", "65%"],
    ["35%", "45%"],
    ["15%", "25%"],
  ],
  [
    ["75%", "85%"],
    ["60%", "70%"],
    ["45%", "55%"],
    ["30%", "40%"],
    ["15%", "25%"],
  ],
];
export default {
  data() {
    return {
      defaultOption: {
        ..._.cloneDeep(baseConfig),
        legend: {
          left: "60%",
          right: "20%",
          top: "middle",
        },
        tooltip: {
          show: true,
          trigger: "item", // 触发类型 - 节点触发
        },
      },
      emptyImg: EmptyImg,
    };
  },
  methods: {
    genCustomOption(chartOption, dimensionData, seriesData) {
      // 系列设值
      chartOption.series = seriesData.map((item, ix) => {
        item.type = "pie";
        item.data = item.data.map((i, index) => {
          return { name: dimensionData[index], value: i };
        });

        item.radius = centerConfig[seriesData.length - 1][ix];
        item.center = ["35%", "50%"];
        item.itemStyle = {
          borderRadius: 5,
          borderWidth: 1,
        };
        item.label = {
          show: false,
        };
        return { ...seriesConfig, ...item };
      });

      // 其余属性合并
      for (const k in this.defaultOption) {
        const opt = this.defaultOption[k];
        chartOption[k] = { ...opt, ...chartOption[k] };
      }
      delete chartOption.xAxis;
    },
  },
};
