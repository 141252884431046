import ComponentStyle from "@/editor_core/transformer/style/componentStyle";
import {
  computed,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import { emitter } from "@/utils/events";
import { generateChartOption } from "./optionHelper";
import { useStore } from "vuex";
import { generateMapInstance } from "./instanceHelper";
import useThemeChange from "./useThemeChange";

export default {
  props: {
    id: { type: String }, // 图表ID
    type: { type: String }, // 图表类型
    componentStyle: { type: Object }, // 图表单一配置数据
    globalStyle: { type: Object }, // 图表全局配置数据
    query: { type: Object }, // 配置文件字段数据
    widget: { type: Object }, // 组件注册原型对象
  },
  setup(props) {
    const store = useStore();
    // 当前小组件对象
    const { proxy } = getCurrentInstance();
    // 主题样式分发器
    const { setChartComponentStyle } = useThemeChange(props);
    // 字段的容器（例如：维度、度量）
    let queryFields = {};

    // 当前图表实例对象
    let mapInstance = {};

    const currentMeasure = ref([]);
    const emptyData = ref(true);
    const emptyImg = computed(() => proxy.emptyImg);

    /**
     * 初始化图表
     * @param {*} data
     * @returns
     */
    const initMap = async (mapData) => {
      // 生成地图option
      generateChartOption(props, queryFields, currentMeasure);
      // 生成对应图层
      proxy.generateLayerMap(mapInstance, mapData, currentMeasure, queryFields);
      // 关闭封面
      emptyData.value = false;
    };
    /**
     * 切换查询维度
     * @param {*} val
     */
    const changeCurrentMeasure = (val) => {
      if (props.widget.multipleData) {
        if (currentMeasure.value.includes(val)) {
          currentMeasure.value = currentMeasure.value.filter(
            (item) => item != val,
          );
        } else {
          currentMeasure.value.push(val);
        }
      } else {
        currentMeasure.value = [val];
      }
      const data = store.getters["editor/getChartData"](props.id);
      // 生成对应图层
      proxy.generateLayerMap(mapInstance, data, currentMeasure, queryFields);
    };
    /**
     * 地图绑定事件
     */
    const headleClickMap = () => {
      // 清除地图上的信息窗体。
      mapInstance.container.clearInfoWindow();
      // 清除的maker
      if (mapInstance.marker) {
        mapInstance.marker.setMap(null);
        mapInstance.marker = null;
      }
    };

    /**
     * 组件挂载
     */
    onMounted(async () => {
      // 生成地图实例
      await generateMapInstance(proxy, props, mapInstance);
      // 开启事件监听
      addEvent();
    });

    /**
     * 组件卸载
     */
    onUnmounted(() => {
      // 关闭事件监听
      offEvent();
    });

    /**
     * 响应全局主题更新
     */
    const handleThemeChange = (setterName) => {
      // 需要使用nexttick，否则不能马上拿到全局样式的最新值
      nextTick(() => {
        if (setterName === "global_styleThemeMode") {
          const mode = props.globalStyle[setterName];
          mapInstance.container.setMapStyle(`amap://styles/${mode}`);
        }
        // 更新当前报表的的style值
        setChartComponentStyle(setterName);
        // 生成对应图层
        const data = store.getters["editor/getChartData"](props.id);
        proxy.generateLayerMap(mapInstance, data, currentMeasure, queryFields);
      });
    };

    /**
     * 响应单个报表更新
     * @param {*} options
     */
    const handleMapChange = (options = {}) => {
      const {
        // 图表数据
        widgetData,
      } = options;

      const { data } = widgetData || {};

      // 没有数据 或 存在无效查询条件 则显示封面图
      if (!data || !Array.isArray(data) || !data.length) {
        emptyData.value = true;
        return;
      }

      // 暂存数据
      store.dispatch("editor/updateChartData", {
        id: props.id,
        data: data,
      });

      // 更新图表
      setTimeout(() => {
        initMap(data);
      }, 0);
    };

    /**
     * 绑定图表事件
     * @returns
     */
    const addEvent = () => {
      emitter.on(`global_theme_change`, handleThemeChange);
      props.id && emitter.on(`chart_data_change_${props.id}`, handleMapChange);
      mapInstance.container.on("click", headleClickMap);
    };

    /**
     * 销毁图表事件
     * @returns
     */
    const offEvent = () => {
      emitter.off(`global_theme_change`, handleThemeChange);
      props.id && emitter.off(`chart_data_change_${props.id}`, handleMapChange);
      mapInstance.container.off("click", headleClickMap);
      mapInstance.container.destroy();
      mapInstance.container = null;
    };

    const renderEmpty = () => {
      return (
        <div className="card-empty" v-show={emptyData.value}>
          <img className="card-empty_icon" src={emptyImg.value}></img>
          <div className="card-empty_text">当前图表暂无数据</div>
        </div>
      );
    };

    const renderMaterial = () => {
      const { titleBarStyle, titleStyle, titleText } =
        ComponentStyle.getTitleConfig(props.componentStyle, props.globalStyle);
      const Title = (
        <>
          <div className="card-widget_title" style={titleBarStyle}>
            <div className="card-widget_title_text" style={titleStyle}>
              {titleText}
            </div>
          </div>
        </>
      );

      const { contentStyle } = ComponentStyle.getContentStyle(
        props.componentStyle,
      );

      const colors = props.globalStyle.global_styleColorScheme.value;
      const Content = (
        <>
          <div className="card-widget_map no-draggable" style={contentStyle}>
            <div style={{ width: "100%", height: "100%" }} id={props.id}></div>
            <div className="card-widget_switch">
              {queryFields.dimension &&
                queryFields.dimension.map((item, i) => {
                  return (
                    <div
                      className={`item ${
                        currentMeasure.value.includes(item) ? "" : "disable"
                      }`}
                      onClick={() => changeCurrentMeasure(item)}
                    >
                      <div
                        className="icon"
                        style={{ backgroundColor: colors[i] }}
                      ></div>
                      <div className="label" style={{ color: colors[i] }}>
                        {item}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      );

      return (
        <div className="card-widget">
          <Title></Title>
          <Content></Content>
        </div>
      );
    };

    return () => {
      const { cardStyle } = ComponentStyle.getCardStyle(
        props.componentStyle,
        props.globalStyle,
      );
      return (
        <div className="card-box" style={cardStyle}>
          {renderEmpty()}
          {renderMaterial()}
        </div>
      );
    };
  },
};
