import _ from "lodash";
const CryptoJS = require("crypto-js");

export function randomStr(length, chars) {
  length = length || 6;
  chars =
    chars || "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export function getDomSize(dom) {
  if (!dom) {
    return;
  }
  return { width: dom.offsetWidth, height: dom.offsetHeight };
}

export function getUrlParam(name) {
  const re = new RegExp("(?:\\?|#|&)" + name + "=([^&#]*)(?:$|&|#)", "i"),
    m = re.exec(window.location.href);
  return m ? m[1] : "";
}

// https://www.cnblogs.com/noah227/p/17654605.html
export const fixResizeObserverError = () => {
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
      callback = _.debounce(callback, 16);
      super(callback);
    }
  };
};

export function isLatLng(str) {
  // 定义经纬度的正则表达式模式
  var pattern =
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
  return pattern.test(str);
}

/**
 * 定义一个函数，用于对字符串进行MD5哈希加密
 * @param {*} str
 * @returns
 */
export function md5(str) {
  // 使用 CryptoJS 的 MD5 方法进行加密
  var hash = CryptoJS.MD5(str);
  // 将加密结果转换为字符串并返回
  return hash.toString();
}
export function removeHashParameter(parameter) {
  let url = window.location.href;
  let hashIndex = url.indexOf("#");
  if (hashIndex !== -1) {
    // 存在哈希部分
    let hash = url.slice(hashIndex + 1); // 获取哈希部分
    let params = new URLSearchParams(hash.split("?")[1]); // 创建 URLSearchParams 对象
    parameter.map((item) => {
      params.delete(item); // 删除指定参数
    });
    let newHash = params.toString(); // 生成新的哈希字符串
    url = url.slice(0, hashIndex) + "#" + newHash; // 构建新的 URL
  }
  // 使用 History API 替换当前 URL
  window.history.replaceState({}, document.title, url);
}
