import { getUrlParam } from "@/utils";
import axios from "axios";
import { ElMessage } from "element-plus";
import cookie from "js-cookie";
import router from "../../router/index.js";

const baseDomain =
  process.env.NODE_ENV === "production"
    ? "//api.cccccl.com/api/v1/"
    : "//127.0.0.1:7001/api/v1/";

const http = axios.create({
  baseURL: baseDomain,
  method: "post",
  headers: {},
});

const requestResolve = async (config) => {
  var token = cookie.get("token");
  config.headers["token"] = token;
  const mode = getUrlParam("mode");
  if (mode === "view") {
    config.headers["mode"] = "view";
  }
  return config;
};

const requestReject = (error) => {
  return Promise.reject(error);
};

const responseResolve = (response) => {
  if (response.status === 200) {
    const { code, result } = response.data;
    if (code === 0) {
      return result;
    } else if (code === 1000) {
      ElMessage.warning("请登陆后再访问");
      router.push("/login");
    } else if (code === 1001) {
      cookie.remove("token");
      ElMessage.warning("登录已过期，请重新登陆");
      router.push("/login");
    }
  }
  return null;
};

const responseReject = (error) => {
  ElMessage({
    type: "error",
    message: `服务器繁忙 状态码${error.response.status}`,
  });
  return Promise.reject(error);
};

http.interceptors.request.use(requestResolve, requestReject);
http.interceptors.response.use(responseResolve, responseReject);

export default http;
