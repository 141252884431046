import selectColorGroup from "./selectColorGroup";
import uploadImage from "./uploadImage";
import iconMultiple from "./iconMultiple";
import selectTheme from "./selectTheme";

const components = [selectColorGroup, uploadImage, iconMultiple, selectTheme];

export default {
  install: (app) => {
    components.forEach((component) => {
      app.component(component.name, component);
    });
  },
};
