import http from "./core/http";

export const getDatasetList = () => {
  return http.get("/dataset");
};

export const getDatasetField = (params) => {
  const dimensionPromise = http.get("/dimension", { params });
  const measurePromise = http.get("/measure", { params });
  return Promise.all([dimensionPromise, measurePromise]);
};

export const getDatasetData = (params) => {
  return http.get("/datasetdata_custom_query", { params });
};
