import { getDomSize } from "@/utils";

export default class ComponentStyle {
  /**
   * 获取报表的大小
   * @param {*} id
   * @returns
   */
  static getChartClientRect(id) {
    const gridItemDom = document.getElementById(`grid-item__${id}`);
    if (!gridItemDom) {
      return;
    }
    const clientRect = getDomSize(gridItemDom);
    return {
      width: clientRect.width,
      height: clientRect.height,
    };
  }

  /**
   * 获取标题区域样式配置
   * @param {*} componentStyle
   * @returns
   */
  static getTitleConfig(componentStyle) {
    const {
      widget_titleVisible,
      widget_titleText,
      widget_titleStyle,
      widget_backgroundColor,
      widget_dividerVisible,
      widget_dividerStyle,
    } = componentStyle;

    // 标题栏文字样式
    const titleStyle = widget_titleVisible
      ? {
          fontSize: widget_titleStyle.fontSize + "px", // 大小
          color: widget_titleStyle.colorPicker, // 颜色
          fontWeight:
            widget_titleStyle.fontStyle.indexOf("bold") > -1
              ? "bold"
              : "initial", // 粗细
          fontStyle:
            widget_titleStyle.fontStyle.indexOf("italic") > -1 ? "italic" : "", // 倾斜
          textAlign: widget_titleStyle.textAlign, // 位置
        }
      : null;
    // 标题栏样式
    const titleBarStyle = {};
    if (widget_titleVisible) {
      if (widget_backgroundColor) {
        titleBarStyle.backgroundColor = widget_backgroundColor;
      }
    } else {
      titleBarStyle.display = "none";
    }
    if (widget_dividerVisible) {
      if (widget_dividerStyle.width && widget_dividerStyle.color) {
        titleBarStyle.borderBottom = `${widget_dividerStyle.width}px solid ${widget_dividerStyle.color}`;
      }
    } else {
      titleBarStyle.borderBottom = "none";
    }

    return {
      titleStyle,
      titleBarStyle,
      titleText: widget_titleText,
    };
  }

  /**
   * 获取内容区域样式配置
   * @param {*} componentStyle
   * @returns
   */
  static getContentStyle(componentStyle) {
    const {
      widget_containerBackgroundVisible,
      widget_containerBackgroundColor,
    } = componentStyle;

    // 内容区域背景色
    const contentStyle = {};
    if (widget_containerBackgroundVisible) {
      contentStyle.backgroundColor = widget_containerBackgroundColor;
    }

    return { contentStyle };
  }

  /**
   * 获取内容区域样式配置
   * @param {*} componentStyle
   * @returns
   */
  static getCardStyle(componentStyle, globalStyle) {
    const { widget_containerBorderVisible, widget_containerBorderStyle } =
      componentStyle;
    const { global_styleCardRadius } = globalStyle;

    const cardStyle = {};
    // 卡片边框
    if (widget_containerBorderVisible) {
      if (widget_containerBorderStyle.color) {
        cardStyle.border = `${widget_containerBorderStyle.width}px solid ${widget_containerBorderStyle.color}`;
      }
    } else {
      cardStyle.border = "none";
    }
    // 卡片圆角
    switch (global_styleCardRadius) {
      case "none":
        cardStyle["borderRadius"] = "0";
        break;
      case "min":
        cardStyle["borderRadius"] = "5px";
        break;
      case "center":
        cardStyle["borderRadius"] = "10px";
        break;
      case "max":
        cardStyle["borderRadius"] = "15px";
        break;
    }

    return { cardStyle };
  }
}
