import { computed, defineComponent } from "vue";
import "./selectColorGroup.scss";
import { colorSystemList } from "@/config";

export default defineComponent({
  name: "select-color-group",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:value", "change"],
  setup(props, ctx) {
    const valueData = computed({
      get: () => {
        return props.value ? props.value : colorSystemList[0].options[0];
      },
      set: (val) => {
        ctx.emit("update:value", val);
      },
    });
    const handleValueChange = (value) => {
      ctx.emit("change", value);
    };

    const getItem = (data) => {
      return (
        <div className="select-color-group-option">
          <div className="colors">
            {data.value.map((color) => {
              return (
                <span
                  className="item"
                  style={{ backgroundColor: color }}
                ></span>
              );
            })}
          </div>
          <div className="label">{data.label}</div>
        </div>
      );
    };

    return () => {
      return (
        <div className="select-color-group">
          <div className="value">{getItem(valueData.value)}</div>
          <el-select
            className="select"
            size="small"
            v-model={valueData.value}
            onChange={handleValueChange}
            popper-class="select-color-group-popper"
          >
            {colorSystemList.map((colors) => {
              return (
                <>
                  <div className="title">{colors.label}</div>
                  {colors.options.map((item) => {
                    return (
                      <el-option key={item} value={item}>
                        {getItem(item)}
                      </el-option>
                    );
                  })}
                </>
              );
            })}
          </el-select>
        </div>
      );
    };
  },
});
