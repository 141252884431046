import { createStore } from "vuex";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const modulesFiles = require.context(
  "./modules",
  true,
  /^\.\/[^/]+?(\.js|\/index\.js)$/,
);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(
    /^\.\/([^/]+?)(\.js|\/index\.js)?$/,
    "$1",
  );
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default createStore({
  modules,
  state,
  getters,
  mutations,
  actions,
});
