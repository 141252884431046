export default () => ({
  editorData: {
    widgetSelectedIndex: -1, // 当前选择的小部件索引
    container: {}, // 容器对象
    widgets: [], // 小部件列表
  },
  currDashboard: {}, // 目标仪表盘对象
  chartDataMap: {}, // 存储每个组件的数据
  dataset: [], // 数据集列表
  intervalDuration: 30, // 实时查询,间隔时长
});
