// 应用基础
import { createApp } from "vue"; // vue框架
import VueGridLayout from "vue-grid-layout"; // 栅格布局

// 引入第三方组件
import ElementPlus from "element-plus"; // UI组件
import "element-plus/dist/index.css";

// mock数据
// import'@/mock/init'
import "@/mock/making";

// 引入自定义组件
import initMaterials from "@/editor_core/materials/init"; // 物料
import initSetters from "@/editor_core/setters/init"; // 设置器

import App from "./App.jsx";
import router from "./router"; // 路由
import store from "./store"; // 状态

import "./assets/style/index.scss"; // 全局样式

import { fixResizeObserverError } from "./utils/index.js";
import "default-passive-events";

fixResizeObserverError();

const app = createApp(App);

app.use(VueGridLayout);
app.use(ElementPlus);
app.use(router);
app.use(store);

initMaterials(app);
initSetters(app);

app.mount("#app");
