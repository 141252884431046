import EmptyImg from "@/assets/img/card/empty/heat.png";

export default {
  data() {
    return {
      defaultOption: {},
      emptyImg: EmptyImg,
    };
  },
  methods: {
    generateLayerMap(mapInstance, mapData, currentMeasure) {
      return new Promise((resolve) => {
        mapInstance.container.clearMap();
        const list = mapData.filter((item) => {
          return currentMeasure.value.includes(item.resourceName);
        });
        mapInstance.container.plugin(["AMap.MarkerCluster"], function () {
          const cluster = new mapInstance.instance.MarkerCluster(
            mapInstance.container,
            list,
            {
              gridSize: 45, //数据聚合计算时网格的像素大小
              zIndex: 100,
            },
          );

          cluster.on("click", function (e) {
            if (mapInstance.marker) {
              mapInstance.marker.setMap(null);
              mapInstance.marker = null;
            }
            const marker = new mapInstance.instance.Marker({
              content: " ",
              anchor: "center",
              zIndex: 200,
              map: mapInstance.container,
            });
            const content = e.clusterData.reduce((sum, curr) => {
              sum += curr.address + "</br>";
              return sum;
            }, "");
            marker.setPosition(e.lnglat);
            marker.setLabel({ content });
            mapInstance.marker = marker;
          });
        });
        resolve();
      });
    },
  },
};
