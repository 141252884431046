/**
 * 检测所有查询规则（例如：required）是否满足
 * @returns
 */
export const getUnvalidQuery = (area) => {
  // 该图标是否有查询配置（用于解析数据）
  if (!area || !area.length) {
    return false;
  }
  for (let item of area) {
    if (item.rule && item.rule.required) {
      if (!item.value.length) {
        return item;
      }
    }
  }
  return false;
};
