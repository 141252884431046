import AMapLoader from "@amap/amap-jsapi-loader";

import MapHeat from "./heat/index.jsx";
import MapBubble from "./bubble/index.jsx";
import MapPitting from "./pitting/index.jsx";

const components = [MapHeat, MapBubble, MapPitting];

export default function initMps(app) {
  app.config.globalProperties.$map = AMapLoader;
  components.forEach((component) => {
    app.component(component.name, component);
  });
}
