import { defineComponent } from "vue";
import main from "./main";
import core from "../index";

const obj = Object.assign({}, core, {
  name: "echart-area",
  mixins: [main],
});

export default defineComponent(obj);
